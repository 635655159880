import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import ResultList from './result-list.model';
import RetornoPaginado from './retorno-paginado.model';

export default abstract class BaseService {
	constructor(protected _httpClient: HttpClient) {}

	protected getPaginado<TResult>(url: string, filtro?: any): Observable<RetornoPaginado<TResult>> {
		return this._httpClient
			.get<ResultList<TResult>>(url, { params: { ...filtro }, observe: 'response' })
			.pipe<RetornoPaginado<TResult>>(map((resp) => new RetornoPaginado(resp.body, resp.body?.total!, resp.body?.totalPages!)));
	}
}
