import ResultList from './result-list.model';

export default class RetornoPaginado<T>{

    public content?: T[] | null;
    public totalElements: number|null;
    public size: number;
    public number: number;

    constructor(content: ResultList<T> | null, totalElements: number | null, totalPages: number){
        this.content = content?.items;
        this.totalElements = totalElements;
        this.size = totalPages
    }
}
